import { useState } from "react";
import "./Footer.scss";
import { ContactActions } from "../../actions/contact";
import WhatsappBtn from "../WhatsappBtn/WhatsappBtn";

const Footer = () => {
  const [information, setInformation] = useState<{ email: string }>({
    email: "",
  });

  const [sent, setSent] = useState(false);
  const [sending, setSending] = useState(false);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      setSending(true);
      await ContactActions.post({
        email: information.email,
        suscribe: true,
        phone: "no phone",
      });
      setSent(true);
    } catch (error) {
      console.log(error);
    } finally {
      setSending(false);
    }
  };
  return (
    <footer className="footer">
      <WhatsappBtn />

      <div className="footer-contact">
        <ul className="footer-list">
          <li>
            <i className="fa fa-address-card footer-intro" />
            Escribime a:
          </li>
          <li className="footer-phone">
            <i className="fa fa-whatsapp fa-fw footer-phone" />
            <a
              href="https://wa.me/5491138522023"
              target="_blank"
              rel="noreferrer"
            >
              +54 9 11 38522023
            </a>
          </li>
          <li className="footer-mail">
            <i className="fa fa-envelope-o fa-fw footer-mail" />
            <a
              href="mailto:infokarinazarfino@gmail.com"
              target="_blank"
              rel="noreferrer"
            >
              infokarinazarfino@gmail.com
            </a>
          </li>
          <li>
            <i className="fa fa-map-marker fa-fw footer-ciudad" />
            C.A.B.A
          </li>
          <li>
            <i className="fa fa-map-marker fa-fw footer-provincia" />
            Buenos Aires | Argentina
          </li>
        </ul>
      </div>
      <form className="footer-contact suscribe" onSubmit={handleSubmit}>
        <span>Ingrese su email para suscribirse a nuestro sitio</span>
        <input
          type="text"
          id="email_suscripcion"
          className="footer-mail-input"
          onChange={(e) => {
            setInformation({ email: e.target.value });
          }}
        />

        <button
          className="footer-mail-input-button"
          style={{ display: "block" }}
          type="submit"
          disabled={sending || sent}
        >
          <i className="fa fa-envelope fa-fw footer-intro"></i>
          {!sending && !sent && "Subscribirse"}
          {sending && "Subscribiendo..."}
          {sent && "Subscripto"}
        </button>
      </form>
      <div className="footer-contact">
        {/* <FacebookEmbed height="300px" customClassName="footer-facebook" /> */}
      </div>
    </footer>
  );
};

export default Footer;
