import { IconButton, TextField, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useContext, useState } from "react";
import { Course } from "../../../../shared/types";
import { DEFAULT_COURSE, DEFAULT_IMAGE } from "../../helpers/defaults";
import { PreviewType } from "./EditMode";
import FileInput from "./FileInput";
import { FileUploader } from "./FileUploader";
import TextEditorWithVideo from "./TextEditorWithVideo";
import { Delete } from "@material-ui/icons";
import { CHARS_TO_REMOVE } from "./WorkshopEdit";
import { ErrorContext } from "../../providers/ErrorContextProvider";
import Modal from "./Modal";
import { Checkbox } from "./Checkbox";

const useStyles = makeStyles((theme) => ({
  courseread: {
    textAlign: "left",
    width: "100%",
    height: "100%",
  },
  title: {
    textAlign: "left",
    width: "50%",
    paddingBottom: "10px",
  },
  description: {
    textAlign: "left",
    width: "90%",
    paddingBottom: "10px",
  },
  image: {
    paddingBottom: "10px",
  },
  input: {
    display: "none",
  },
}));

interface CourseReadProps {
  course?: Course;
  onSave: (course: Course) => void;
  onCancel: () => void;
  onPreview: (course: Course, type: PreviewType) => void;
}
const CourseRead = (props: CourseReadProps) => {
  const classes = useStyles();

  const errorContext = useContext(ErrorContext);

  const [course, setCourse] = useState<Course>(props.course ?? DEFAULT_COURSE);

  return (
    <Modal
      onSave={() => props.onSave(course)}
      onCancel={props.onCancel}
      //onPreview={(type) => props.onPreview(course, type)}
    >
      <div className={classes.courseread}>
        <div className={classes.image}>
          <FileInput
            type={"image"}
            path={course.image?.path}
            onUpload={(image: string) =>
              setCourse({ ...course, image: { path: image } })
            }
            onRemove={() => setCourse({ ...course, image: DEFAULT_IMAGE })}
          />
        </div>
        <div>
          <TextField
            className={classes.title}
            required
            label="Títutlo"
            variant="outlined"
            onChange={(e) => setCourse({ ...course, title: e.target.value })}
            value={course.title}
          />
        </div>
        <div>
          <Checkbox
            label="Habilitado"
            onChange={(state) => setCourse({ ...course, enabled: state })}
            checked={course.enabled === undefined || !!course.enabled}
          />
        </div>
        <div>
          <TextEditorWithVideo
            initialText={course.text}
            onLateUpdate={(text) => setCourse({ ...course, text })}
          />
        </div>
        <div>
          {course.file ? (
            <>
              <Typography variant="h5">Descargable</Typography>
              <p>Nombre de archivo: {course?.file.slice(CHARS_TO_REMOVE)}</p>
              <a href={course?.file} download>
                Descargar
              </a>
              <IconButton
                onClick={() => setCourse({ ...course, file: "" })}
                style={{ padding: "10px" }}
              >
                <Delete></Delete>
              </IconButton>
            </>
          ) : (
            <FileUploader
              path="https://karinazarfino.com/scripts/fileUploadScript.php"
              uploadText={"Subir"}
              selectFileText={"Seleccionar archivo"}
              onUpload={(file) => setCourse({ ...course, file })}
              onError={(m) => errorContext.setMessage(m)}
              title={"Descargable"}
            />
          )}
        </div>
      </div>
    </Modal>
  );
};

export default CourseRead;
